/* navbar.css */

@font-face {
  font-family: 'Kumbh Sans';
  src: url('../assest/KumbhSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  font-family: "Kumbh Sans", sans-serif;
}

.navbar .navba-nav .nav_font {
  font-size: 1.25rem;
  color: #000; 


}

.navbar-container {
  transition: all 0.3s ease-in-out;
  position: fixed;
  width: 100%;
  z-index: 1000;
  
}

.navbar-container.scrolled {
  transform: translateY(-40px); /* Adjust based on your navbar's height to hide it */
  justify-content: center;

}

.navbar-container.scrolled .navbar {
  background-color: rgba(255, 255, 255, 0.9); /* Optional: change background on scroll */
  padding: 7px 0; /* Reduce padding to make navbar smaller */
}

.navbar-container.scrolled .logo {
  height: 55px; /* Adjust logo size for the shrunken navbar */
}

.logo.large-logo {
  display: block;
}

.logo.small-logo {
  display: none;
}


.partners-section{

  background-color: rgb(220, 218, 218);
}



.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50vh; /* Adjust the height as needed */
  width: 100%;
  background-image: url('../assest/car1.jpeg'); /* Set the path to your background image */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.hero-box {
  position: absolute;
  text-align: center;
  max-width: 90%; /* Allows some padding on the sides */
  z-index: 2; /* Ensures text appears above the images */
}
  .hero-text {
    background-color: rgba(163, 156, 156, 0.3); /* Semi-transparent black background */
  padding: 20px;
  border-radius: 20px; /* Optional: to have rounded corners */
  display: inline-block; /* Makes the div inline for the text-align center from .hero-box to work */
  max-width: 90%;

  }
  
  .carousel-item img {
    object-fit: cover; /* Ensures images cover the area without losing aspect ratio */
    min-height: 100vh; /* Ensures image covers full viewport height */
    width: 100%;
  }
 
  .row.mx-0 {
    margin-right: 0;
    margin-left: 0;
  }
  
  h3.text-center {
    margin-bottom: 30px;
    font-size: 2rem;
    color: #333;
  }
  .partner-image {
    max-height: 100px; /* Adjust to desired height */
    width: 60%; /* Ensures the width is automatically adjusted */
    object-fit: contain; /* Keeps the aspect ratio */
    display: block; /* Ensures that margin: auto is effective */
    margin-left: auto; /* Centers the image horizontally */
    margin-right: auto; /* Centers the image horizontally */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */

  }

  .partner-image:hover {
    transform: scale(1.05); /* Slightly increase the size of the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Cast a subtle shadow */
  }
  
  /* Create this new class for the columns that contain the logos */
  .partner-col {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 50px; /* Add padding to create more space between columns */
  }
  
  
  
  .nav-pills .nav-link {
    text-align: center;
    font-size: 1.25rem; /* Adjust the font size as needed */
    color: black; /* This is the text color */
    background-color: whitesmoke; /* This is the background color */
    margin-bottom: 0.5rem; /* Add some space between the tabs */
    border-style: solid;
    border-width: 2px;
    border-color: #025d87 ;
    
  }
  
  .nav-pills .nav-link.active {
    color: #fff;
    background-color: #025d87; /* Slightly darker background color for the active tab */
  }
  .nav-pills .nav-link:hover,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active:focus {
  color: #fff;
  background-color: #0056b3; /* Even darker color on hover/focus */
}
.m-2{
  background-color: #0056b3;
}
  
  .tab-content {
    background-color: #f8f8f8;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .tab-pane {
    color: #555;
  }

  .services-header {
  padding-left: 15px; 
  font-size: 2rem;
  color: #333;
  text-align: left; 
  padding-top: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.tab-pane p {
  text-align: center;
}

.footer {
  background-color: #34AB49; /* Footer background color */
  color: whitesmoke; /* Text color */
  padding: 20px 0; /* Padding for the top and bottom */
  font-size: 15px;
}

.footer h5 {
  color: whitesmoke; /* Text color */
  font-size: 15px;
    border-bottom: 1px solid whitesmoke; /* Underline for the heading */
  padding-bottom: 10px; /* Space between heading and underline */
}

.footer ul {
  list-style: none; /* Remove default list styling */
  padding-left: 0; /* Align list to the left */
 


}

.footer ul li {
  padding: 5px 0; /* Space between list items */

}

.footerHead{
  
}

.footer .contact-details {
  padding-left: 15px; /* Indent for the contact details */
}

.footer .contact-details li {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
}

.footer .contact-details li svg {
  margin-right: 10px; /* Space between icon and text */
}
.tabContentStyle {
  min-height: '400px';
    display: 'flex';
    align-items: 'center';
    justify-content: 'center'
}
.headerName{
  text-decoration: underline;
}

nav{
  display: flex;
  align-items: center ;
  justify-content: flex-start;
  box-shadow: 0 5px 15px ;
  background-size:cover;
  background-position: center center;
  background-color: white;
}

#nAv{
  padding: 35px 80px;

}
#navTabs{
  display: flex;
  align-items:center ;
  justify-content: center;
  margin-left: 0;
  padding-top: 20px;
  padding-right: 2px;
}

#navTabs li{
  list-style: none;
  padding: 0 20px;
  position: relative;
  


}
.tab-name{
  font-size: 1.3em;
  color: #2c3e50;
}

#navTabs li a{
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 600;
  color: #656565;
  transition: 0.2s ease-in-out;
}

#navTabs li a:hover,
#navTabs li a.active{
  color: #34AB49;
}

#navTabs li a:hover::after,
#navTabs li a.active:after {
  content: "";
  width: 40%;
  height: 2px;
  background: #34AB49;
  position: absolute;
  bottom: -4px;
  left: 27px;

}

#mobile{
  display: none;

}
#ba {
  font-size: 100px; /* Increase the font size as needed */
  cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
}

#mobile i{
  color: black;
}


.hero-text {
  
  color: #fff;
  background-color: rgba(100, 99, 99, 0.3); /* Semi-transparent black background */
  padding: 20px; /* Add some padding around the text */
  width: 70%; /* Control the width of the text container */
  max-width: 700px; /* Set a max-width if needed */
  box-sizing: border-box; /* To ensure padding doesn't affect the width */
  text-align: center; /* Center the text */
  border-radius: 20px; /* Optional: to have rounded corners */
}

 .lead {
  word-break: normal;
  hyphens: auto;
  font-size: 30px;
}

.subHero{
  font-size: 40px;

}

.jumbotron-heading {
  font-size: 50px;
}

#serTabs {
  max-width: 1200px; /* Adjust this value to match the width of other page elements */
  margin-left: auto; /* This will push the element to the center */
  margin-right: auto; /* This will push the element to the center */
  padding: 20px; /* Optional: Adds padding inside the container */
}

.logo {
  width: auto; /* Adjust based on your layout */
  height: 85px; /* Original height, adjust as needed for responsiveness */
  transition: height 0.3s ease; /* Smooth transition for resizing */
  max-height: 80%;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  align-items: center;
  padding: 5px 0; /* Space between list items */

  
}
.serviceHeader {
  text-align: center;
  padding-left: 20px;
  color: #2c3e50;
  font-family: "Kumbh Sans", Sans-serif;
  font-weight: 700;
  font-size: 130px;
  position: relative;
  z-index: 1;
}

.services-header-img {
  display: block; /* Allows margin to be applied properly */
  max-width: 100%; /* Ensures the image is not larger than its container */
  height: auto; /* Keeps the aspect ratio of the image */
  margin: 0 auto; /* Centers the image horizontally */
  width: 40%; 
}

.headingfont{
  font-family: "Kumbh Sans", sans-serif;

}




.dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: whitesmoke;
  margin-top: 10px;

}

.menuDrop{
  padding-top: 20px;

}


.specializations-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px; /* Adjust the space between columns */
}

.specialization {
  background-color: #d5d5d5; /* Light grey background */
  padding: 20px;
  border: 1px solid transparent; /* Transparent border for a smooth transition on hover */
  transition: border-color 0.3s ease-in-out; /* Smooth transition for border color */
  width: calc(33.333% - 20px);
  border-radius: 20px;
  transition: transform 0.3s ease; /* Smooth transition for transform property */
 
}

.specialization:hover {
  transform: scale(1.04); /* Slightly increase the size on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow for depth */
}
@media (max-width: 1000px) {
  .specializations-section {
    flex-direction: column;
    align-items: center; /* Ensures that child elements are centered after they stack */
  }
  
  .specialization {
    width: 100%; /* Make each item take full width on smaller screens */
    margin-bottom: 20px; /* Adds space between items when they stack */
  }
  .tab-name{
    font-size: 1.1em;
    color: #2c3e50;
    text-align: center;
  }
  .services-header-image{
    width: 100px;
  }
}
.serHeader{
  font-family: "Raleway", Sans-serif;
    font-weight: 700;
    color: #d52b05;
    padding: 20px;
    font-size: 50px;
    

}

.Cont-specialization {
  background-color: #d5d5d5; /* Light grey background */
  padding: 20px;
  border: 1px solid transparent; /* Transparent border for a smooth transition on hover */
  text-align: center; /* Center the text */
  transition: border-color 0.3s ease-in-out; /* Smooth transition for border color */
  width: 100%;

  
}



.specialization:hover {
  border-color: #C0FF6B; /* Highlighted border color on hover */
}

.specialization .icon {
  display: flex;
  justify-content: center; /* Center the icon horizontally */
  font-size: 24px; /* Adjust icon size as needed */
  margin-bottom: 10px;
}


.specialization h3 {
  margin-top: 10px; /* Adjust as needed */
}

.ui.vertical.tabular.menu {
  width: 150px; /* Smaller width for tab menu */
}

.ui.tab.segment {
  margin-left: 150px; /* Offset the same amount as the menu width */
}




.specialization ul {
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
}

.specialization ul li {
  padding: 5px 0; /* This adds vertical space between list items */
}






.dropdown-menu a {
  display: block; /* Makes the link fill the padded area, increasing clickable area */
  color: #000; /* Adjust color as needed */
  text-decoration: none; /* Removes underline from links */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.dropdown:hover .dropdown-menu {
  display: block;
  padding-top: 20px;
  padding-bottom: 30px;

}
.dropdown-menu li {
  padding: 10px 15px; /* Adjust padding to increase spacing */
}
.dropdown-menu li + li {
  margin-top: 5px; /* Adds space between items */
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4.5px;
  margin-left: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  
  

}

.dropdown > a {
  display: flex;
  align-items: center; /* This centers the arrow vertically with the tab text */
  
  
}


.arrow.down {
  transform: rotate(45deg);
  

}

.arrow.up {
  transform: rotate(-135deg);

}


.services{
  padding-top: 20px;
}

.services-section-header {
  padding-top: 40px;

  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
  color: black ;
  width: 600px;
  font-family: "Kumbh Sans", sans-serif;

}

.services-section-header h3{
  font-size: 20px;
  font-family: "Kumbh Sans", sans-serif;
  word-wrap: break-word; /* Ensures long words can break and wrap onto the next line */
  hyphens: auto;
  overflow-wrap: break-word;
  

}

.services-section-subheader{
  color: #34AB49 ;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 15px ;

}

.services-section-header, .service-item div {
  box-sizing: border-box; /* Includes padding in the element's total width and height */
}
.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: left; /* Ensures there's space between items */
  gap: 20px; /* Adds space between the items */
}

.service-item {
  display: flex;
  align-items: center;
  background-color: #D5D5D5; /* Light gray background */
  border: 1px solid transparent; /* Invisible border that will change on hover */
  padding: 2%;
  transition: border-color 0.3s ease; /* Smooth transition for the hover effect */
  width: calc(40% - 10px); /* Adjusts width to fit 2 items per row */
  margin: 1%;
  margin-bottom: 1em;
  box-sizing: border-box; /* Ensures padding does not affect the total width */
  border-radius: 20px;
  transition: transform 0.3s ease; /* Smooth transition for transform property */

}


/* Style for the text next to the icon */
.service-item div {
  flex-grow: 1;
  padding-left: 10px; /* Adjust the spacing between the icon and the text as needed */
}

/* Hover effect for the service item */
.service-item:hover {
  border-color: #C0FF6B; /* change the border color on hover */
  cursor: pointer; /* changes the mouse pointer to indicate it's clickable */
  transform: scale(1.01); /* Slightly increase the size on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.support-subItem:hover {
  border-color: #C0FF6B; /* change the border color on hover */
  cursor: pointer; /* changes the mouse pointer to indicate it's clickable */
  transform: scale(1.01); /* Slightly increase the size on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
/* Responsive adjustments if necessary */

@media (max-width: 1000px) {
  .services-list {
    justify-content: center; /* Centers items when they stack */
  }

  .service-item {
    width: 100%; /* Makes each item take full width on smaller screens */
    /* Optional: adjust padding or other styles for smaller screens */
  }
}
.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 10px;
  box-sizing: border-box;
}

.content-box {
  background-color: #d5d5d5;
  border-radius: 20px;
  border: 1px solid transparent;
  transition: transform 0.3s ease; /* Smooth transition for transform property */

  
}

.content-box:hover {
  border: 1px solid ;
  transition: 0.3s;
  border-color: #C0FF6B  ;
  transform: scale(1.01); /* Slightly increase the size on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.leftImg, .rightImg {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftImg img, .rightImg img {
  width: 40%;
  max-width: 180px; /* Adjust the max-width as needed */
  height: auto;
}

.text-section {
  flex: 2;
  max-width: 35em;
  text-align: left;
  padding-left: 8px; /* Only for the left text section */
}

.text-section h3 {
  color: #34AB49;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 25px;
}

.text-section p {
  font-size: 17px;
  color: black;
}

/* For the Cyberark section, if the image is on the right */
.rightImg {
  padding-right: 8px; /* Only for the right image section */
}



.offerings-container {
  background-color: white;
  padding: 3rem 0; /* Padding top and bottom */
  color: black;
  background-size:cover;
  background-position: top;
}

.offerings-content {
  max-width: 1200px; /* Adjust this value as per your design requirements */
  margin-left: auto; /* This will center the container */
  margin-right: auto; /* This will center the container */
  padding: 0 1rem; /* Padding on the sides */


}
.offering-card {
  /* ... existing styles ... */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}

.offering-card:hover {
  transform: scale(1.05); /* Slightly increase the size on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow for depth */
}

.offerings-header {
  text-align: center;
  margin-bottom: 2rem; /* Space below the header */
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 700;
  font-size: 90px;
}

.offerings-grid {
  display: grid;
  /* Assuming there are 10 items, this will create 2 rows of 5 columns each */
  grid-template-columns: repeat(3, 1fr); 
  gap: 1rem; /* Space between items */
  justify-content: center;
  align-items: center;
  padding: 1rem; /* Padding inside the grid */

}


.offering-card {
  display: flex;
  flex-direction: column;
  
  align-items: center; /* This centers the content horizontally */
  padding: 1rem; /* Add padding to each card as needed */

}

.offering-icon {
  font-size: 5rem; /* Icon size */
  size: 25px;
  margin-bottom: 1rem; /* Space between the icon and the text */
  color: black;
  
}

.services-header-img{

}
.servMain .header-image {
  position: absolute;
  top: 0; 
  right: 0; 
  z-index: 2; 
  padding-top: 50;
}

.offering-name {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 1rem; /* Space below the text if you have more content */
}



/* Tab item hover border color */
.custom-tab-menu .item:hover {
  border-color: #C0FF6B !important; /* Set your desired hover border color */
}

.support-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  
}

.support-blurb {
}

.support-list ul {
  list-style-type: none; /* Removes default list styling */
}

.support-list li::before {
  list-style-type:none !important;  /* Removes default list styling */
}

.support-list {
  color: black;
  padding-left: 15px;
 

  list-style-type:none !important;  /* Removes default list styling */

}

.services-header-image {
  display: block; /* Allows margin to be applied properly */
  max-width: 100%; /* Ensures the image is not larger than its container */
  height: auto; /* Keeps the aspect ratio of the image */
  margin: 0 auto; /* Centers the image horizontally */
  width: 200px; /* Adjust the width as needed */
}

.services-headerPart-image {
  display: block; /* Allows margin to be applied properly */
  max-width: 100%; /* Ensures the image is not larger than its container */
  height: auto; /* Keeps the aspect ratio of the image */
  margin: 0 auto; /* Centers the image horizontally */
  width: 260px; /* Adjust the width as needed */
}


.support-list h4 {
  color: #34AB49;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px; /* Space between the header and the list */
}

.support-list ul {
  list-style-type:none !important;  /* Removes default list styling */
  padding-left: 0; /* Removes default padding */
  font-size: 20px;
  list-style: none !important;
}

.support-list ul li {
  display: flex; /* Aligns icons and text */
  align-items: center; /* Centers items vertically */
  padding: 15px 0; /* Space between list items */
}


.career-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  padding: 25px;
  margin: auto;
  max-width: 1100px; /* Adjust to match the max width of your layout */
}

.career-banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.career-header {
  font-size: 3rem;
  margin: 0;
  color: #d52b05; /* or any other color as per design */
  font-family: "Raleway", Sans-serif;
  font-weight: 700;
}

.career-subtext {
  font-size: 1.5rem;
  margin: 0;
  color: black; /* or any other color as per design */
  font-family: "Raleway", Sans-serif;

}
.jumboImage{
  width: 370px;
  padding-bottom: 15px;
}



.career-button {
  background-color: #2c3e50; /* Example color */
  color: white;
  border: none;
  padding: 30px 40px;
  cursor: pointer;
  font-size: 2rem;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  font-family: "Raleway", Sans-serif;

}

.career-button:hover {
  background-color: #C0FF6B; /* Darker shade for hover effect */
}

/* Add responsive styling if needed */
@media (max-width: 600px) {
  .hero-text h1 {
    font-size: 20px; /* Smaller font size for h1 on very small screens */
  }
  .hero-text p {
    font-size: 14px; /* Smaller font size for paragraph text on very small screens */
  }

  .jumboImage{
    width: 180px
    }
}


@media screen and (max-width: 1100px){
  .logo {
    height: 75px; /* Adjust the logo size for smaller screens */
  }
  #navTabs{
    display: flex;
    flex-direction: column;
    align-items:flex-start ;
    justify-content: flex-start;
    position: fixed;
    top: 125px;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: white;
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
    z-index: 1000; 

  }
  nav{

    box-shadow: 0 5px 15px ;
    background-size:cover;
    background-position: center center;
  
    
  }

  #navTabs.active{
    right: 0px;
  }

  #navTabs li {
    margin-bottom: 25px;
  }
  #mobile{
    display: block;
  }

  #mobile i{
    font-size: 24px;
    cursor: pointer;
  }
  
}





@media (max-width: 1000px) {
  .logo.large-logo {
    display: none;
  }

  .logo.small-logo {
    display: block;
  }
}

@media (max-width: 768px) {
  .career-section {
    flex-direction: column;
    align-items: center;
  }

  .career-section h2 {
    font-size: 1.75rem; /* Smaller font size for mobile */
  }
  .logo.large-logo {
    display: none;
  }

  .logo.small-logo {
    display: block;
  }
}

.career-banner {
  display: flex;
  justify-content: space-between; /* This will align the text to the left and the button to the right */
  align-items: center; /* Aligns items vertically */
  padding: 20px;
  font-family: "Raleway", Sans-serif;
  font-weight: 700;
  font-size: 1.75em; /* Large font for the heading */
  color: #2c3e50;
}

.career-text {
  text-align: left; /* Align text to the left */
}

.career-text h1 {
  color: #d52b05; /* Example color */
  font-family: "Raleway", Sans-serif;
  font-weight: 700;
  font-size: 45px;
  margin: 0 0 10px 0; /* Adjust the margin as needed */
}

.career-text p {
  margin: 0; /* Removes default margins */
}

.career-button {
  padding: 20px 20px;
  cursor: pointer;
  font-weight: bold;
  background-color: #2c3e50;
  align-self: flex-start; /* Aligns the button at the start of the flex item */
  color: white
}

/* Hover effect for the button */
.career-button:hover {
  background: #d52b05;
  color: white;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.business-inquiries {
  background-color: #7896b3; /* Keeps the background color full width */
  padding: 2rem 0; /* Padding top and bottom */
  color: whitesmoke;
  text-align: center;
}

.inquiries-text h1 {
  color: whitesmoke; /* Adjust the color as needed */
}

.inquiries-text p {
  margin-bottom: 20px;
}

.inquiries-button {
  background-color: #28a745; /* Example green color */
  color: whitesmoke;
  /* Add more button styling as needed */
}

.business-button{
  padding: 10px 15px;
  cursor: pointer;
  font-weight: bold;
  background-color: #2c3e50;
  align-self: flex-start; /* Aligns the button at the start of the flex item */
  color: white;
  border-radius: 20px;
}

.business-header{
  text-align: center;
  margin-bottom: 2rem; /* Space below the header */
  color: whitesmoke;
  font-family: "Raleway", Sans-serif;
  font-weight: 700;
  font-size: 45px;
}

.business-button:hover {
  background: #d52b05;
  color: white;
}

.vivere-style{
  font-weight: 700;
  color: black;
}

.IGAHeader{
  color: #34AB49;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 25px;
}
.services-section-point{
  font-size: 15px;
  color: black;
}

@media (max-width: 768px) {
  .services-section-header {
    padding: 0 15px; /* Adds some padding on the sides to prevent text from touching the edges */
  }

  .services-section-header h3 {
    font-size: 15px; /* Optionally, adjust the font size for smaller screens */
    word-wrap: break-word;
    overflow-wrap: normal;
  }
  .services-section-subheader {
    font-size: 15px; /* Optionally, adjust the font size for smaller screens */
    word-wrap: break-word;
    overflow-wrap: normal;
  }

  .services-section-point{
    font-size: 10px;
  }

  .support-list h4{
    padding-top: 20px;
    font-size: 15px;

  }

.support-sublist li{
  font-size: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
  cursor: pointer;
}



.support-sublist li:hover {
  transform: scale(1.05); /* Slightly increase the size on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow for depth */
}

  .service-item div {
    padding: 0 10px; /* Adds padding to the text container inside each service item */
  }
  .services-section-header, .service-item div {
    padding: 0 10px; /* Adjust padding to ensure text doesn't touch the edges */
  }
  

}


@media (max-width: 600px) {
  .specializations-section, .content-container {
    flex-direction: column;
    align-items: center;
  }

  .specialization, .content-box {
    width: 100%;
    margin-bottom: 20px;
  }

  .specialization .icon, .service-item div .icon {
    margin: 0 auto; /* Center icons */
    margin-bottom: 10px; /* Add some space below the icon */
  }

  .services-section-header h3, .text-section h3, .support-blurb h3 {
    font-size: 18px; /* Adjust font size for readability */
  }

  .service-item, .support-list ul li {
    flex-direction: column; /* Stack icon and text vertically */
    align-items: center;
  }

  .leftImg img, .rightImg img {
    width: 60%; /* Adjust image sizes */
    margin: 0 auto; /* Center images */
  }

  /* Adjust tab menu for vertical layout if necessary */
  .custom-tab-menu {
    display: block;
  }
}

.aboutUsContainer {
  font-family: "Kumbh Sans", sans-serif;
  margin: 0 auto;
  max-width: 1200px;
}

.aboutUsHeader, .aboutUsCoreFocus, .aboutUsWhy, .aboutUsValues {
  text-align: center;
  padding: 40px 20px;
}

.aboutUsHeader h1 {
  color: black;
  margin-bottom: 10px;
  font-size: 50px;
  font-family: "Kumbh Sans", sans-serif;

}
.aboutUsHeader p{
  font-size: 19px;
  font-family: "Kumbh Sans", sans-serif;

}
.aboutUsWhy p{
  font-size: 17px;
}

.aboutUsCoreFocus {
  background-color: #D5D5D5;
  font-family: "Kumbh Sans", sans-serif;

}

.corefocus-header-image{
  display: block; /* Allows margin to be applied properly */
  max-width: 100%; /* Ensures the image is not larger than its container */
  height: auto; /* Keeps the aspect ratio of the image */
  margin: 0 auto; /* Centers the image horizontally */
  width: 400px; /* Adjust the width as needed */

}

.corevalue-header-image{
  display: block; /* Allows margin to be applied properly */
  max-width: 100%; /* Ensures the image is not larger than its container */
  height: auto; /* Keeps the aspect ratio of the image */
  margin: 0 auto; /* Centers the image horizontally */
  width: 300px; /* Adjust the width as needed */

}

.buss-header-image{
  display: block; /* Allows margin to be applied properly */
  max-width: 100%; /* Ensures the image is not larger than its container */
  height: auto; /* Keeps the aspect ratio of the image */
  margin: 0 auto; /* Centers the image horizontally */
  width: 450px; /* Adjust the width as needed */

}
.aboutUsCoreFocus h2{
  font-family: "Kumbh Sans", sans-serif;
  color: black;
  font-size: 50px;

}

.aboutUsWhy h2{
  font-family: "Kumbh Sans", sans-serif;
  color: black;
  font-size: 30px;

}

.aboutUsValues h2{
  font-family: "Kumbh Sans", sans-serif;
  color: black;
  font-size: 30px;

}

.valuesList{
  font-family: "Kumbh Sans", sans-serif;

}
.aboutUsWhy p{
  font-family: "Kumbh Sans", sans-serif;

}

.focusAreas {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.focusItem {
  width: 30%;
  min-width: 250px;
  margin: 10px;
  padding: 20px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease; /* Smooth transition for transform property */

}
.focusItem:hover {
  transform: scale(1.05); /* Slightly increase the size on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow for depth */
}

.focusItem h3 {
  color: #34AB49;
  font-family: "Kumbh Sans", sans-serif;

}

.aboutUsWhy {
  background-color: #D5D5D5;
  color: #495057;
}
.whyImage{
  width: 800px;
}
.aboutUsValues ul {
  list-style: none;
  padding: 0;
  
}

.valuesList li {
  background: linear-gradient(135deg, green 0%, #C0FF6B 100%);
  color: white;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
  font-size: large;
  transition: transform 0.3s ease; /* Smooth transition for transform property */

}
.valuesList li:hover {
  transform: scale(1.01); /* Slightly increase the size on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow for depth */
}

/* Add responsive design */
@media (max-width: 768px) {
  .focusAreas {
    flex-direction: column;
    align-items: center;
  }

  .focusItem {
    width: 80%;
  }
}




.careersContainer {
  text-align: center;
  padding: 50px 20px;
  background-color: #D5D5D5;
}

.careersContainer h2 {
  color: black;
  margin-bottom: 20px;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 40px;
}

.careersContainer p {
  color: black;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 20px;
}

.jobListings {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  padding: 20px 0;
}

.job {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.job:hover {
  transform: translateY(-5px);
}

.job h3 {
  color: #34AB49;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 30px;
  

}

.job button {
  background: linear-gradient(135deg, green 3%, #34AB49 100%);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
    font-family: "Kumbh Sans", sans-serif;

}

.job button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .jobListings {
    grid-template-columns: 1fr;
  }
}

.inquiriesContainer {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.inquiriesContainer h2 {
  color: #203864;
  margin-bottom: 10px;
}

.contactInfo {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.contactInfo h3 {
  color: #333;
  margin-bottom: 15px;
}

.contactInfo p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 10px;
}

.bus-con{
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  transition: background-color 0.3s ease;

}
.bus-con:hover {
  transform: scale(1.01); /* Slightly increase the size on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow for depth */
}